<template>
  <div class="auth-wrapper auth-v1">
    <div class="text-center mt-4">
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          class="logo-content"
          alt="logo"
        />
        <h2 class="brand-text mb-0">
          {{ appName }}
        </h2>
      </b-link>
    </div>

    <b-row class="auth-inner m-0 d-lg-flex align-items-center mt-4">
      <b-col
        lg="8"
        class="py-5 mx-auto"
      >
        <summary-rekomendasi-akhir
          v-if="rekomendasiAkhir"
          :rekap-detail="rekomendasiAkhir"
        />
        <b-alert
          v-else
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            <span>Status Clearance</span>
          </h4>
          <div class="alert-body">
            <span>Belum Tersedia</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BImg, BAlert,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

import SummaryRekomendasiAkhir from '../rekomendasi/SummaryRekomendasiAkhir.vue'

export default {
  components: {
    BAlert,
    BLink,
    BImg,
    BRow,
    BCol,
    SummaryRekomendasiAkhir,
  },
  data() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      rekomendasiAkhir: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$http.get('/recommendation/final-recommendations', {
        params: {
          key: this.$route.params.id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.rekomendasiAkhir = res.data.data
          }
        })
    })
  },
}
</script>

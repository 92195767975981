<template>
  <div
    v-if="show"
    class="list-summary-rekomendasi mt-1 border-top-secondary"
  >
    <template v-if="others.length !== 0">
      <h5 class="mt-1 font-weight-normal">
        Rekapitulasi Rekomendasi
      </h5>
      <b-list-group
        class="mb-1"
        flush
      >
        <b-list-group-item
          v-for="(data, index) in others"
          :key="index"
          class="d-flex justify-content-between"
        >
          <div v-html="data.recommendation.replace(/\n/g, '<br>')" />
          <div
            v-if="data.author"
            style="width:120px"
            class="text-right"
          >
            <b-badge
              v-for="(people, keyd) in data.author"
              :key="keyd"
              v-b-tooltip.hover
              variant="light-info"
              class="ml-25"
              size="sm"
              :title="people"
            >
              {{ findInitials(people) }}
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </template>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BBadge, VBTooltip,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataUtama: {
      type: Object,
      default: () => {},
    },
    detailProgram: {
      type: Object,
      default: () => {},
    },
    stepRekomendasi: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      show: false,
      userData: getUserData(),
      others: [],
      mode: false,
      listRekapLanjut: [],
      listRekapTidak: [],
    }
  },
  mounted() {
    this.getTemplateReko()
  },
  methods: {
    copyReko(id) {
      navigator.clipboard.writeText(id)
    },
    findInitials(input) {
      return input.split(' ').map(item => item.toUpperCase().substring(0, 1)).join('')
    },
    getTemplateReko() {
      if (this.stepRekomendasi !== 'default') {
        if (this.stepRekomendasi === 'program') {
          this.targetUrl = 'business-data'
        }
        if (this.stepRekomendasi === 'dokumen') {
          this.targetUrl = 'document-data'
        }
        if (this.stepRekomendasi === 'layanan') {
          this.targetUrl = 'services-data'
        }
        if (this.stepRekomendasi === 'datainformasi') {
          this.targetUrl = 'information-data'
        }
        if (this.stepRekomendasi === 'keamanan') {
          this.targetUrl = 'security-data'
        }

        const params = {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.dataUtama.data_utama_id,
        }
        if (this.stepRekomendasi === 'program') {
          params.proses_bisnis_id = this.detailProgram.proses_bisnis_id
        }
        if (this.stepRekomendasi === 'dokumen') {
          params.clearance_id = this.dataUtama.clearance_id
        }
        if (this.stepRekomendasi === 'layanan') {
          params.layanan_id = this.detailProgram.layanan_id
        }
        if (this.stepRekomendasi === 'datainformasi') {
          params.data_informasi_id = this.detailProgram.data_informasi_id
        }
        if (this.stepRekomendasi === 'keamanan') {
          params.keamanan_id = this.detailProgram.keamanan_id
        }

        this.$http.get(`/recommendation/${this.targetUrl}`, {
          params,
        })
          .then(res => {
            if (res.data.status === 'success') {
              if (res.data.data.users_recommendations.length !== 0) {
                const keys = []
                res.data.data.users_recommendations.map(usereco => {
                  if (keys.indexOf(usereco.rekomendasi_id) === -1) {
                    keys.push(usereco.rekomendasi_id)
                    const reko = {
                      rekomendasi: usereco.rekomendasi_text,
                      author: [usereco.fullname],
                    }
                    this.others.push(reko)
                  }
                  const lrlAuth = this.others[keys.indexOf(usereco.rekomendasi_id)].author

                  if (lrlAuth.indexOf(usereco.fullname) === -1) {
                    this.others[keys.indexOf(usereco.rekomendasi_id)].author.push(usereco.fullname)
                  }
                  return true
                })
              }
            }
          })

        this.show = true
      }
    },
  },
}
</script>

<template>
  <div class="list-summary-rekomendasi">
    <h5 class="mt-1 font-weight-normal">
      {{ statusRekomendasi }}
    </h5>
    <b-list-group
      class="mb-1"
      flush
    >
      <b-list-group-item
        v-for="(data, index) in rekapData"
        :key="index"
        class="d-flex justify-content-between"
      >
        <div v-html="data.recommendation.replace(/\n/g, '<br>')" />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    rekapDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statusRekomendasi: '',
      rekapData: [],
    }
  },
  mounted() {
    if (this.rekapDetail) {
      this.statusRekomendasi = this.rekapDetail.status
      this.rekapData = this.rekapDetail.rekomendasi
    }
  },
}
</script>

<template>
  <validation-observer :ref="`formInfra-edit-${appData.id}`">
    <b-form :id="`form-edit-inf-${appData.id}`">
      <b-card class="border-bottom">
        <b-row>
          <alert-module-detail :doc-data="appData" />
          <b-col md="4">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Domain Infrastruktur"
              label-for="vi-inf_domain_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Domain Infrastruktur"
                rules="required"
              >
                <v-select
                  id="vi-inf_domain_id"
                  v-model="inf_domain_id"
                  required
                  name="inf_domain_id"
                  :options="daftarDomainInfra"
                  placeholder="Domain Infrastruktur"
                  label="nama"
                  @input="getAreaInfra()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Area Infrastruktur"
              label-for="vi-inf_area_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Area Infrastruktur"
                rules="required"
              >
                <v-select
                  id="vi-inf_area_id"
                  v-model="inf_area_id"
                  required
                  name="inf_area_id"
                  :options="daftarAreaInfra"
                  placeholder="Area Infra"
                  label="nama"
                  @input="getKategoriInfra()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Kategori Infrastruktur"
              label-for="vi-inf_category_id"
            >
              <v-select
                id="vi-inf_category_id"
                v-model="inf_category_id"
                name="inf_category_id"
                :options="referenceData.ref_kategori_infra"
                placeholder="Kategori Infrastruktur"
                label="nama"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              v-b-tooltip.hover.top="`Nama ${inf_domain_id.name} yang digunakan.`"
              :label="`Nama ${nama_infra}`"
              label-for="vi-nama"
            >
              <validation-provider
                #default="{ errors }"
                :name="`Nama ${nama_infra}`"
                rules="required"
              >
                <b-form-input
                  id="vi-nama"
                  v-model="appData.nama"
                  required
                  name="nama"
                  :placeholder="`Nama Perangkat Keras Server`"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <template v-if="inf_area_id.area_infra_id === 4">
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan jumlah bandwidth internet yang tersedia selama 1 tahun.'"
                label="Bandwidth Internet"
                label-for="vi-bandwidth "
              >
                <validation-provider
                  #default="{ errors }"
                  name="Bandwidth Internet"
                  rules="required"
                >
                  <b-input-group append="mbps">
                    <b-form-input
                      id="vi-bandwidth "
                      v-model="appData.bandwidth"
                      type="number"
                      required
                      name="bandwidth"
                      placeholder="Bandwidth Internet"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan lokasi dari fasilitas.'"
                label="Lokasi"
                label-for="vi-lokasi"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lokasi"
                  rules="required"
                >
                  <b-form-input
                    id="vi-lokasi"
                    v-model="appData.lokasi"
                    required
                    name="lokasi"
                    placeholder="Lokasi"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan unit pemilik dari fasilitas.'"
                label="Kepemilikan"
                label-for="vi-kepemilikan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Kepemilikan"
                  rules="required"
                >
                  <v-select
                    id="vi-kepemilikan"
                    v-model="kepemilikan"
                    required
                    name="kepemilikan"
                    :options="referenceData.ref_kepemilikan"
                    placeholder="Pilih Salah Satu"
                    label="nama"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan unit kerja/perangkat daerah yang bertanggung jawab atas fasilitas.'"
                label="Unit Kerja Penanggung Jawab"
                label-for="vi-unit_kerja"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Unit Kerja Penanggung Jawab"
                  rules="required"
                >
                  <b-form-input
                    id="vi-unit_kerja"
                    v-model="appData.unit_kerja"
                    required
                    name="unit_kerja"
                    placeholder="Unit Kerja Penanggung Jawab"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan klasifikasi tier fasilitas.'"
                label="Klasifikasi Tier Fasilitas"
                label-for="vi-tier"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Klasifikasi Tier Fasilitas"
                  rules="required"
                >
                  <b-form-input
                    id="vi-tier"
                    v-model="appData.tier"
                    required
                    name="tier"
                    placeholder="Klasifikasi Tier Fasilitas"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Merupakan tipe pengaman fasilitas.'"
                label="Sistem Pengamanan Fasilitas"
                label-for="vi-sistem_pengaman"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Sistem Pengamanan Fasilitas"
                  rules="required"
                >
                  <b-form-input
                    id="vi-sistem_pengaman"
                    v-model="appData.sistem_pengaman"
                    required
                    name="sistem_pengaman"
                    placeholder="Sistem Pengamanan Fasilitas"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                v-b-tooltip.hover.top="'Penjelasan dari Pusat Data yang digunakan.'"
                label="Deskripsi Pusat Data"
                label-for="vi-deskripsi"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Deskripsi Pusat Data"
                  rules="required"
                >
                  <b-form-textarea
                    id="vi-deskripsi"
                    v-model="appData.deskripsi"
                    required
                    name="deskripsi"
                    placeholder="Deskripsi Pusat Data"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </template>
          <template v-if="inf_area_id.area_infra_id === 2">
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Nama pemilik server yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri.'"
                label="Nama Pemilik"
                label-for="vi-nama_pemilik"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Pemilik"
                  rules="required"
                >
                  <b-form-input
                    id="vi-nama_pemilik"
                    v-model="appData.nama_pemilik"
                    required
                    name="nama_pemilik"
                    placeholder="Nama Pemilik"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Jenis penggunaan dari server yang digunakan, jenis penggunaan tersebut a.l.: Web Server; Mail Server; Aplikasi; Database; File Server; Active Directory, keamanan informasi.'"
                label="Jenis Penggunaan Server"
                label-for="vi-jenis"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jenis Penggunaan Server"
                  rules="required"
                >
                  <v-select
                    id="vi-jenis"
                    v-model="appData.jenis"
                    required
                    name="jenis"
                    :options="daftarJenisServer"
                    placeholder="Pilih Salah Satu"
                    label="nama"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Status kepemilikan dari server yang digunakan'"
                label="Kepemilikan"
                label-for="vi-ownership_status"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Kepemilikan"
                  rules="required"
                >
                  <v-select
                    id="vi-kepemilikan"
                    v-model="kepemilikan"
                    required
                    name="kepemilikan"
                    :options="referenceData.ref_kepemilikan"
                    placeholder="Pilih Salah Satu"
                    label="nama"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Unit pengelola server yang digunakan.'"
                label="Unit Pengelola Server"
                label-for="vi-unit_operasional"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Unit Pengelola Server"
                  rules="required"
                >
                  <b-form-input
                    id="vi-unit_operasional"
                    v-model="appData.unit_operasional"
                    required
                    name="unit_operasional"
                    placeholder="Unit Pengelola Server"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Lokasi dari perangkat server yang digunakan, pilihan lokasi didapat dari metadata fasilitas.'"
                label="Lokasi Perangkat Keras Server"
                label-for="vi-lokasi"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lokasi Perangkat Keras Server"
                  rules="required"
                >
                  <b-form-input
                    id="vi-lokasi"
                    v-model="appData.lokasi"
                    required
                    name="lokasi"
                    placeholder="Lokasi Perangkat Keras Server"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                v-b-tooltip.hover.top="'Berisi deskripsi dari perangkat server yang digunakan.'"
                label="Deskripsi Perangkat Keras Server"
                label-for="vi-deskripsi"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Deskripsi Perangkat Keras Server"
                  rules="required"
                >
                  <b-form-textarea
                    id="vi-deskripsi"
                    v-model="appData.deskripsi"
                    required
                    name="deskripsi"
                    placeholder="Deskripsi Perangkat Keras Server"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </template>
          <template v-if="inf_area_id.area_infra_id === 3">
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Nama pemilik Government cloud yang digunakan.'"
                label="Nama Pemilik"
                label-for="vi-nama_pemilik"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Pemilik"
                  rules="required"
                >
                  <b-form-input
                    id="vi-nama_pemilik"
                    v-model="appData.nama_pemilik"
                    required
                    name="nama_pemilik"
                    placeholder="Nama Pemilik"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Jenis dari Government cloud yang digunakan (PaaS, IaaS, SaaS, BDaaS, dan SecaaS).'"
                label="Jenis Komputasi Awan Pemerintahan"
                label-for="vi-jenis"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jenis Komputasi Awan Pemerintahan"
                  rules="required"
                >
                  <v-select
                    id="vi-jenis"
                    v-model="appData.jenis"
                    required
                    name="jenis"
                    :options="daftarJenisCloud"
                    placeholder="Pilih Salah Satu"
                    label="nama"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Status kepemilikan dari Government cloud yang digunakan.'"
                label="Kepemilikan"
                label-for="vi-ownership_status"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Kepemilikan"
                  rules="required"
                >
                  <v-select
                    id="vi-kepemilikan"
                    v-model="kepemilikan"
                    required
                    name="kepemilikan"
                    :options="referenceData.ref_kepemilikan"
                    placeholder="Pilih Salah Satu"
                    label="nama"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Unit operasional Government cloud yang digunakan.'"
                label="Unit Operasional Komputasi Awan Pemerintahan"
                label-for="vi-unit_operasional"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Unit Pengelola Server"
                  rules="required"
                >
                  <b-form-input
                    id="vi-unit_operasional"
                    v-model="appData.unit_operasional"
                    required
                    name="unit_operasional"
                    placeholder="Unit Pengelola Server"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                v-b-tooltip.hover.top="'Unit yang melakukan pembangunan dan pengembangan Government cloud.'"
                label="Unit Pengembang Komputasi Awan Pemerintahan"
                label-for="vi-unit_pengembang"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Unit Pengembang Komputasi Awan Pemerintahan"
                  rules="required"
                >
                  <b-form-input
                    id="vi-unit_pengembang"
                    v-model="appData.unit_pengembang"
                    required
                    name="unit_pengembang"
                    placeholder="Unit Pengembang Komputasi Awan Pemerintahan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                v-b-tooltip.hover.top="'Penjelasan dari Government cloud yang digunakan.'"
                label="Deskripsi Komputasi Awan Pemerintahan"
                label-for="vi-deskripsi"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Deskripsi Komputasi Awan Pemerintahan"
                  rules="required"
                >
                  <b-form-textarea
                    id="vi-deskripsi"
                    v-model="appData.deskripsi"
                    required
                    name="deskripsi"
                    placeholder="Deskripsi Komputasi Awan Pemerintahan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </template>
          <b-col>
            <b-button
              variant="outline-success"
              class="mt-0 mt-md-2"
              @click="simpanInfra"
            >
              <span>Simpan Referensi Infrastruktur</span>
            </b-button>
            <b-button
              v-if="dataSaved"
              variant="flat-success"
              size="sm"
              class="mt-0 mt-md-2 ml-50"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="14"
              /> Referensi Infrastruktur Berhasil Disimpan
            </b-button>
          </b-col>
        </b-row>

        <div v-if="errorMsg">
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              <ul v-if="typeof errorMsg === 'object'">
                <li
                  v-for="(item, index) in errorMsg"
                  :key="index"
                >
                  {{ index }}:
                  <span
                    v-for="(child, indexs) in item"
                    :key="indexs"
                  >
                    {{ child }}
                  </span>
                </li>
              </ul>
              <span v-else>{{ errorMsg }}</span>
            </div>
          </b-alert>
        </div>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BForm, BButton, BCard, BRow, BCol, BFormGroup, BFormTextarea, VBTooltip,
  BInputGroup, BAlert,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import AlertModuleDetail from '../detail/AlertModuleDetail.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    AlertModuleDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    appData: {
      type: Object,
      default: () => {},
    },
    coreData: {
      type: Object,
      default: () => {},
    },
    referenceData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      errorMsg: '',
      dataSaved: false,
      reqData: 0,
      data_utama_id: '',
      infrastruktur_id: '',
      nama_infra: 'Fasilitas Komputasi',
      inf_domain_id: {
        domain_infra_id: 1,
        nama: 'Fasilitas Komputasi',
      },
      inf_area_id: {
        area_infra_id: 4,
        nama: 'Pusat Data',
      },
      inf_category_id: null,
      nama: '',
      nama_pemilik: '',
      jenis: '',
      kepemilikan: null,
      unit_operasional: '',
      lokasi: '',
      deskripsi: '',
      daftarKepemilikan: ['Milik Sendiri', 'Milik Instansi Lain', 'Milik BUMN', 'Milik Pihak Ketiga'],
      daftarJenisServer: ['Web Server', 'Mail Server', 'Aplikasi', 'Database', 'File Server', 'Active Directory', 'Keamanan Informasi'],
      daftarMasterInfra: { infrastruktur_id: 'new', label: 'Tambah Referensi Infrastruktur Baru' },
      daftarDomainInfra: [{
        domain_infra_id: 1,
        nama: 'Fasilitas Komputasi',
      }, {
        domain_infra_id: 2,
        nama: 'Platform',
      }],
      daftarAreaInfra: [],
      daftarKategoriInfra: [],
    }
  },
  watch: {
    actionStep() {
      this.simpanInfra()
    },
    referenceData() {
      this.setReference()
    },
  },
  mounted() {
    this.data_utama_id = Number(this.$route.params.id)
    this.setReference()
  },
  methods: {
    setReference() {
      if (Object.keys(this.referenceData).length !== 0) {
        this.referenceData.ref_domain_infra.map(value => {
          if (value.nama === this.appData.domain_infrastruktur) {
            this.inf_domain_id = value
          }
          return true
        })
        this.getAreaInfra()

        this.referenceData.ref_area_infra.map(value => {
          if (value.nama === this.appData.area_infrastruktur) {
            this.inf_area_id = value
          }
          return true
        })
        this.getKategoriInfra()

        if (this.appData.kepemilikan) {
          this.referenceData.ref_kepemilikan.map(value => {
            if (value.nama === this.appData.kepemilikan) {
              this.kepemilikan = value
            }
            return true
          })
        }
      }
    },
    getAreaInfra() {
      this.inf_area_id = ''
      this.inf_category_id = ''

      if (this.inf_domain_id.domain_infra_id === 1) {
        this.inf_area_id = {
          area_infra_id: 4,
          domain_infra_id: 1,
          nama: 'Pusat Data',
        }
        this.nama_infra = this.inf_area_id.nama
        this.daftarAreaInfra = [this.inf_area_id]
      }
      if (this.inf_domain_id.domain_infra_id === 2) {
        this.daftarAreaInfra = [{
          area_infra_id: 2,
          domain_infra_id: 2,
          nama: 'Kerangka Infrastruktur dan Aplikasi',
        }, {
          area_infra_id: 3,
          domain_infra_id: 2,
          nama: 'Komputasi Awan',
        }]
      }
    },
    getKategoriInfra() {
      this.daftarKategoriInfra.splice(0)
      this.inf_category_id = null

      this.nama_infra = this.inf_area_id.nama
      if (this.inf_area_id.area_infra_id === 2) {
        this.inf_category_id = {
          area_infra_id: 2,
          kategori_infra_id: 7,
          nama: 'Perangkat Keras Server',
        }
      }
    },
    simpanInfra() {
      this.reqData += 1

      this.$refs[`formInfra-edit-${this.appData.id}`].validate()
        .then(success => {
          if (success) {
            this.saveInfra()
          } else {
            const data = {
              status: false,
              id: this.appData.infrastruktur_id,
              msg: 'Terdapat Kesalahan Validasi Infrastruktur, Periksa Input Infrastruktur Anda.',
            }
            this.$emit('step-completed', data)
          }
        })
        .catch(error => {
          const data = {
            status: false,
            index: (this.appData.ubah) ? `new-${this.appData.id}` : this.appData.id,
            type: 'inf',
            msg: error.message,
          }
          this.errorMsg = error.message
          this.$emit('step-completed', data)
        })
    },
    saveInfra() {
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        instansi_id: this.userData.instansi_id,
        infrastruktur_id: this.appData.id,
        data_utama_id: this.coreData.data_utama_id ? this.coreData.data_utama_id : this.data_utama_id,
        domain_infra_id: this.inf_domain_id.domain_infra_id,
        area_infra_id: this.inf_area_id.area_infra_id,
        nama: this.appData.nama,
        nama_pemilik: this.appData.nama_pemilik,
        jenis: this.appData.jenis,
        unit_operasional: this.appData.unit_operasional,
        unit_pengembang: this.appData.unit_pengembang,
        lokasi: this.appData.lokasi,
        deskripsi: this.appData.deskripsi,
        unit_kerja: this.appData.unit_kerja,
        bandwidth: this.appData.bandwidth,
        tier: this.appData.tier,
        sistem_pengaman: this.appData.sistem_pengaman,
      }
      if (this.inf_category_id !== null) {
        metaUtama.kategori_infra_id = this.inf_category_id.kategori_infra_id
      }
      if (this.kepemilikan) {
        metaUtama.kepemilikan_id = this.kepemilikan.kepemilikan_id
      }

      this.$http.post('/clearance/infrastruktur', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
        },
      })
        .then(res => {
          this.infrastruktur_id = res.data.data.infrastruktur_id
          const data = {
            status: true,
            msg: res.data.data,
          }
          data.msg.data_utama_id = this.coreData.data_utama_id ? this.coreData.data_utama_id : this.data_utama_id
          this.dataSaved = true
          this.errorMsg = ''
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.errorMsg = error.response.data.error
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>

<template>
  <form-wizard
    color="#7367F0"
    :title="null"
    :subtitle="null"
    layout="vertical"
    finish-button-text="Simpan"
    back-button-text="Sebelumnya"
    class="vertical-steps steps-transparent mb-3"
    @on-complete="submitForm"
    @on-change="stepCheck"
  >

    <!-- Data Utama tab -->
    <!-- :before-change="validateDataUtama" -->
    <tab-content
      title="Data Utama"
      :before-change="validateDataUtama"
      :lazy="true"
    >
      <validation-observer ref="formDataUtama">
        <b-form v-if="stepAnggaran === 'krisna'">
          <tambah-data-utama-krisna
            :action-step="stepDataUtama"
            @step-completed="stepCompleted"
          />
        </b-form>
        <b-form v-if="stepAnggaran === 'sakti'">
          <tambah-data-utama-baru
            :action-step="stepDataUtama"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Peta Rencana tab -->
    <!-- :before-change="validatePetaRencana" -->
    <tab-content
      title="Peta Rencana"
      :before-change="validatePetaRencana"
      :lazy="true"
    >
      <validation-observer ref="formPetaRencana">
        <b-form>
          <tambah-peta-rencana
            :core-data="clearanceData"
            :clearance-id="data_utama_id"
            :action-step="stepPetaRencana"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Infrastruktur -->
    <!-- :before-change="validateInfra" -->
    <tab-content
      title="Infrastruktur"
      :before-change="validateInfra"
      :lazy="true"
    >
      <validation-observer ref="formInfra">
        <b-form>
          <tambah-infrastruktur-single
            :core-data="clearanceData"
            :sia-guid="siaGuid"
            :sia-kegiatan-guid="siaKegGuid"
            :sia-map-data="siaMap"
            :clearance-id="data_utama_id"
            :action-step="stepInfra"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <!-- Proses Bisnis tab -->
    <!-- :before-change="validateBisnis" -->
    <tab-content
      title="Proses Bisnis"
      :before-change="validateBisnis"
      :lazy="true"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Proses Bisnis
          </h5>
          <small class="text-muted">Arsitektur Domain Proses Bisnis SPBE yang terkait dengan kegiatan.</small>
        </b-col>
      </b-row>
      <div
        v-for="(item, index) in totalProbis"
        :id="`infra-${item.id}`"
        :key="item.id"
        ref="row"
        :title="`Data Proses Bisnis ${item.id}`"
      >
        <div class="border-bottom mb-0 card-text p-0 d-flex">
          <b-button
            v-b-toggle
            :href="`#collapse-infra-${item.id}`"
            variant="flat-secondary"
            class="text-left flex-grow-1"
            @click.prevent
          >
            Data Proses Bisnis {{ item.id }}
          </b-button>
          <b-button
            variant="flat-danger"
            @click="removeProbis(index)"
          >
            <feather-icon
              icon="XIcon"
            />
          </b-button>
        </div>
        <b-collapse
          :id="`collapse-infra-${item.id}`"
          class="mb-1"
          visible
        >
          <tambah-bisnis-multi
            :key="`inf_${item.id}`"
            :core-data="clearanceData"
            :app-data="item"
            :clearance-id="data_utama_id"
            :reference-data="probisRefData"
            :sakti="sakti"
            :action-step="stepBisnis"
            @step-completed="stepCompleted"
          />
        </b-collapse>
      </div>

      <b-row>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-button
            variant="success"
            class="mt-0 mt-md-2"
            @click="repeateProbis"
          >
            <span>Tambah Proses Bisnis</span>
          </b-button>
        </b-col>
      </b-row>
    </tab-content>

    <!-- Aplikasi -->
    <!-- :before-change="validateAplikasi" -->
    <tab-content
      title="Aplikasi"
      :before-change="validateAplikasi"
      :lazy="true"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Aplikasi
          </h5>
          <small class="text-muted">Arsitektur Domain Aplikasi SPBE untuk seluruh Aplikasi yang terkait dengan kegiatan.</small>
        </b-col>
      </b-row>

      <div
        v-for="(item, index) in totalAplikasi"
        :id="`aplikasi-${item.id}`"
        :key="item.id"
        ref="row"
        :title="`Data Aplikasi ${item.id}`"
      >
        <div class="border-bottom mb-0 card-text p-0 d-flex">
          <b-button
            v-b-toggle
            :href="`#collapse-app-${item.id}`"
            variant="flat-secondary"
            class="text-left flex-grow-1"
            @click.prevent
          >
            Data Aplikasi {{ item.id }}
          </b-button>
          <b-button
            variant="flat-danger"
            @click="removeAplikasi(index)"
          >
            <feather-icon
              icon="XIcon"
            />
          </b-button>
        </div>
        <b-collapse
          :id="`collapse-app-${item.id}`"
          visible
          class="mb-1"
        >
          <tambah-aplikasi-multi
            :key="`app_${item.id}`"
            :core-data="clearanceData"
            :new-index="index"
            :app-data="item"
            :clearance-id="data_utama_id"
            :action-step="stepAplikasi"
            :reference-data="appRefData"
            @step-completed="stepCompleted"
          />
        </b-collapse>
      </div>

      <b-row>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-button
            variant="success"
            class="mt-0 mt-md-2"
            @click="repeateAplikasi"
          >
            <span>Tambah Aplikasi</span>
          </b-button>
        </b-col>
      </b-row>
    </tab-content>

    <!-- Layanan -->
    <!-- :before-change="validateLayanan" -->
    <tab-content
      title="Layanan"
      :before-change="validateLayanan"
      :lazy="true"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Layanan
          </h5>
          <small class="text-muted">Arsitektur Domain Layanan SPBE yang terkait dengan kegiatan.</small>
        </b-col>
      </b-row>
      <div
        v-for="(item, index) in totalLayanan"
        :id="`infra-${item.id}`"
        :key="item.id"
        ref="row"
        :title="`Data Layanan ${item.id}`"
      >
        <div class="border-bottom mb-0 card-text p-0 d-flex">
          <b-button
            v-b-toggle
            :href="`#collapse-infra-${item.id}`"
            variant="flat-secondary"
            class="text-left flex-grow-1"
            @click.prevent
          >
            Data Layanan {{ item.id }}
          </b-button>
          <b-button
            variant="flat-danger"
            @click="removeLayanan(index)"
          >
            <feather-icon
              icon="XIcon"
            />
          </b-button>
        </div>
        <b-collapse
          :id="`collapse-layanan-${item.id}`"
          class="mb-1"
          visible
        >
          <tambah-layanan-multi
            :key="`inf_${item.id}`"
            :core-data="clearanceData"
            :app-data="item"
            :clearance-id="data_utama_id"
            :action-step="stepLayanan"
            @step-completed="stepCompleted"
          />
        </b-collapse>
      </div>

      <b-row>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-button
            variant="success"
            class="mt-0 mt-md-2"
            @click="repeateLayanan"
          >
            <span>Tambah Layanan</span>
          </b-button>
        </b-col>
      </b-row>
    </tab-content>

    <!-- Keamanan -->
    <!-- :before-change="validateKeamanan" -->
    <tab-content
      title="Keamanan"
      :before-change="validateKeamanan"
      :lazy="true"
    >
      <validation-observer ref="formKeamanan">
        <b-form>
          <tambah-keamanan
            :clearance-id="data_utama_id"
            :action-step="stepKeamanan"
            @step-completed="stepCompleted"
          />
        </b-form>
      </validation-observer>
    </tab-content>

    <div v-if="errorMsg">
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Error Found
        </h4>
        <div class="alert-body">
          <ul v-if="typeof errorMsg === 'object'">
            <li
              v-for="(item, index) in errorMsg"
              :key="index"
            >
              {{ index }}:
              <span
                v-for="(child, indexs) in item"
                :key="indexs"
              >
                {{ child }}
              </span>
            </li>
          </ul>
          <span v-else>{{ errorMsg }}</span>
        </div>
      </b-alert>
    </div>

  </form-wizard>
</template>

<script>
import store from '@/store'
import { ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BForm, BAlert, VBTooltip, VBToggle, BRow, BCol, BButton, BCollapse,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import TambahDataUtamaBaru from '../tambah-kegiatan/TambahDataUtamaBaru.vue'
import TambahDataUtamaKrisna from '../tambah-kegiatan/TambahDataUtamaKrisna.vue'
import TambahPetaRencana from '../tambah-kegiatan/TambahPetaRencana.vue'
import TambahInfrastrukturSingle from '../tambah-kegiatan/TambahInfrastrukturSingle.vue'
import TambahBisnisMulti from '../tambah-kegiatan/TambahBisnisMulti.vue'
import TambahLayananMulti from '../tambah-kegiatan/TambahLayananMulti.vue'
import TambahAplikasiMulti from '../tambah-kegiatan/TambahAplikasiMulti.vue'
import TambahKeamanan from '../tambah-kegiatan/TambahKeamanan.vue'

export default {
  components: {
    ValidationObserver,
    FormWizard,
    TabContent,
    BAlert,
    BForm,
    BRow,
    BCol,
    BButton,
    BCollapse,

    TambahDataUtamaBaru,
    TambahDataUtamaKrisna,
    TambahPetaRencana,
    TambahInfrastrukturSingle,
    TambahBisnisMulti,
    TambahLayananMulti,
    TambahAplikasiMulti,
    TambahKeamanan,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      stepAnggaran: 'krisna',
      userData: getUserData(),
      stepChange: 0,
      stepDataUtama: 0,
      stepPetaRencana: 0,
      stepInfra: 0,
      stepBisnis: 0,
      stepLayanan: 0,
      stepAplikasi: 0,
      stepKeamanan: 0,
      letter_id: '',
      jenis_pengadaan_id: 0,
      data_utama_id: 0,
      clearanceData: {},
      errorMsg: '',
      form: {},
      message: '',
      totalLayanan: [],
      jumlahLayanan: 0,
      totalProbis: [],
      jumlahProbis: 0,
      probisRefData: {},
      totalAplikasi: [],
      jumlahAplikasi: 0,
      appRefData: {},
      sakti: {},
      siaGuid: '',
      siaKegGuid: '',
      siaMap: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 99 || role > 110) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/pengajuan/surat')
    } else {
      next()
    }
  },
  created() {
    localStorage.removeItem('dataBaru')
    this.letter_id = this.$route.params.id
    this.jenis_pengadaan_id = this.$route.params.jenis
  },
  mounted() {
    if (process.env.VUE_APP_STEP === 'sakti') {
      this.stepAnggaran = 'sakti'
    }
    this.$http.get('/clearance', {
      params: {
        token: localStorage.getItem('userToken'),
        clearance_id: this.letter_id,
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.clearanceData = res.data.data
        }
      })
  },
  methods: {
    getProbisReference() {
      this.$http.get('/clearance/business-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.probisRefData = res.data.data
          }
        })
    },
    getAppReference() {
      this.$http.get('/clearance/applications/app-list', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.appRefData = res.data.data
          }
        })
    },
    repeateLayanan() {
      const jumlah = this.jumlahLayanan + 1
      this.totalLayanan.push({
        id: jumlah,
      })
      this.jumlahLayanan = jumlah
    },
    removeLayanan(data) {
      this.totalLayanan.splice(data, 1)
    },
    repeateProbis() {
      const jumlah = this.jumlahProbis + 1
      this.totalProbis.push({
        id: jumlah,
      })
      this.jumlahProbis = jumlah
    },
    removeProbis(data) {
      this.totalProbis.splice(data, 1)
    },
    repeateAplikasi() {
      const jumlah = this.jumlahAplikasi + 1
      this.totalAplikasi.push({
        id: jumlah,
      })
      this.jumlahAplikasi = jumlah
    },
    removeAplikasi(data) {
      this.totalAplikasi.splice(data, 1)
    },
    validateAplikasi() {
      if (this.jumlahAplikasi !== 0) {
        return new Promise(resolve => {
          this.stepAplikasi += 1
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
            } else {
              this.data_utama_id = value.msg.data_utama_id
              this.errorMsg = ''
            }
            resolve(value.status)
          })
        })
      }
      this.errorMsg = 'Referensi Aplikasi Tidak Boleh Kosong'
      return false
    },
    validateDataUtama() {
      this.$refs.formDataUtama.validate()
        .then(this.stepDataUtama += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
            localStorage.setItem('dataBaru', value.msg.data_utama_id)
            this.getProbisReference()
            this.getAppReference()
          }
          resolve(value.status)
        })
      })
    },
    async validatePetaRencana() {
      const isValid = await this.$refs.formPetaRencana.validate()
      if (isValid) {
        this.stepPetaRencana += 1
        this.errorMsg = ''

        return new Promise(resolve => {
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
            } else {
              this.data_utama_id = value.msg.data_utama_id
              this.errorMsg = ''
            }
            resolve(value.status)
          })
        })
      }
      this.errorMsg = 'Validation failed'
      return false

      // this.$refs.formPetaRencana.validate()
      //   .then(this.stepPetaRencana += 1)

      // return new Promise(resolve => {
      //   this.$on('step-validated', value => {
      //     if (!value.status) {
      //       this.errorMsg = value.msg
      //     } else {
      //       // this.data_utama_id = value.msg.data_utama_id
      //       this.errorMsg = ''
      //     }
      //     resolve(value.status)
      //   })
      // })
    },
    validateInfra() {
      this.$refs.formInfra.validate()
        .then(this.stepInfra += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateBisnis() {
      if (this.jumlahProbis !== 0) {
        return new Promise(resolve => {
          this.stepBisnis += 1
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
            } else {
              this.data_utama_id = value.msg.data_utama_id
              this.errorMsg = ''
            }
            resolve(value.status)
          })
        })
      }
      this.errorMsg = 'Referensi Proses Bisnis Tidak Boleh Kosong'
      return false
    },
    validateLayanan() {
      if (this.jumlahLayanan !== 0) {
        return new Promise(resolve => {
          this.stepLayanan += 1
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
            } else {
              this.data_utama_id = value.msg.data_utama_id
              this.errorMsg = ''
            }
            resolve(value.status)
          })
        })
      }
      this.errorMsg = 'Referensi Layanan Tidak Boleh Kosong'
      return false
    },
    validateKeamanan() {
      this.$refs.formKeamanan.validate()
        .then(this.stepKeamanan += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.data_utama_id = value.msg.data_utama_id
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    submitForm() {
      localStorage.removeItem('dataBaru')
      this.$router.replace({ name: 'kegiatan-detail', params: { id: this.data_utama_id } })
    },
    stepCheck(e) {
      this.stepChange = e
      this.errorMsg = ''
    },
    stepCompleted(data) {
      this.sakti = store.state.app.sakti
      this.siaGuid = store.state.app.siaGuid
      this.siaKegGuid = store.state.app.siaKegiatanGuid
      this.siaMap = store.state.app.siaMap
      this.$emit('step-validated', data)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

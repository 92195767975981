<template>
  <b-card
    border-variant="transparent"
    bg-variant="transparent"
    class="shadow-none"
  >
    <!-- about -->
    <h3 class="text-center mb-2">
      Status Permohonan
    </h3>
    <app-timeline>
      <app-timeline-item
        :variant="(statusProgram.draft !== '') ? 'info': 'primary'"
        class="pb-50"
      >
        <h6>Draft</h6>
        <p>{{ statusProgram.draft | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.draft_lengkap"
        :variant="(statusProgram.draft_lengkap) ? 'info': 'secondary'"
        class="pb-50"
      >
        <h6>Draft Lengkap</h6>
        <p>{{ statusProgram.draft_lengkap | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.submit"
        :variant="(statusProgram.submit) ? 'info': 'secondary'"
        class="pb-50"
      >
        <h6>Submit</h6>
        <p>{{ statusProgram.submit | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.verifikasi_teknis"
        :variant="(statusProgram.verifikasi_teknis) ? 'info': 'secondary'"
        class="pb-50"
      >
        <h6>Verifikasi Teknis</h6>
        <ul
          v-if="typeof statusProgram.verifikasi_teknis === 'object'"
          class="px-2"
        >
          <li
            v-for="(item, index) in statusProgram.verifikasi_teknis"
            :key="index"
          >
            <div class="mb-1">
              <span class="font-weight-bold">{{ formatStatus(index) }}</span><br>
              <span v-if="item && item !== ''">{{ item | formatDate }}</span>
              <span v-else>
                <b-badge
                  pill
                  variant="light-danger"
                >
                  Belum
                </b-badge>
              </span>
            </div>
          </li>
        </ul>
        <p v-else>
          {{ statusProgram.verifikasi_teknis | formatDate }}
        </p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.rekomendasi_teknis"
        :variant="(statusProgram.rekomendasi_teknis) ? 'info': 'secondary'"
        class="pb-50"
      >
        <h6>Rekomendasi Teknis</h6>
        <ul
          v-if="typeof statusProgram.rekomendasi_teknis === 'object'"
          class="px-2"
        >
          <li
            v-for="(item, index) in statusProgram.rekomendasi_teknis"
            :key="index"
          >
            <div class="mb-1">
              <span class="font-weight-bold">{{ formatStatus(index) }}</span><br>
              <span v-if="item && item !== ''">{{ item | formatDate }}</span>
              <span v-else>
                <b-badge
                  pill
                  variant="light-danger"
                >
                  Belum
                </b-badge>
              </span>
            </div>
          </li>
        </ul>
        <p v-else>
          {{ statusProgram.rekomendasi_teknis | formatDate }}
        </p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.rek_eselon_2"
        :variant="(statusProgram.rek_eselon_2) ? 'info': 'secondary'"
        class="pb-50"
      >
        <h6>Rekomendasi Eselon 2</h6>
        <ul
          v-if="typeof statusProgram.rek_eselon_2 === 'object'"
          class="px-2"
        >
          <li
            v-for="(item, index) in statusProgram.rek_eselon_2"
            :key="index"
          >
            <div class="mb-1">
              <span class="font-weight-bold">{{ formatStatus(index) }}</span><br>
              <span v-if="item && item !== ''">{{ item | formatDate }}</span>
              <span v-else>
                <b-badge
                  pill
                  variant="light-danger"
                >
                  Belum
                </b-badge>
              </span>
            </div>
          </li>
        </ul>
        <p v-else>
          {{ statusProgram.rekomendasi_teknis | formatDate }}
        </p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.rekomendasi_sementara"
        :variant="(statusProgram.rekomendasi_sementara) ? 'info': 'secondary'"
        class="pb-50"
      >
        <h6>Pemberian Rekomendasi</h6>
        <p>{{ statusProgram.rekomendasi_sementara | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.rek_eselon_1"
        :variant="(statusProgram.rek_eselon_1) ? 'info': 'secondary'"
        class="pb-50"
      >
        <h6>Rekomendasi Eselon 1</h6>
        <ul
          v-if="typeof statusProgram.rek_eselon_1 === 'object'"
          class="px-2"
        >
          <li
            v-for="(item, index) in statusProgram.rek_eselon_1"
            :key="index"
          >
            <div class="mb-1">
              <span class="font-weight-bold">{{ formatStatus(index) }}</span><br>
              <span v-if="item && item !== ''">{{ item | formatDate }}</span>
              <span v-else>
                <b-badge
                  pill
                  variant="light-danger"
                >
                  Belum
                </b-badge>
              </span>
            </div>
          </li>
        </ul>
        <p v-else>
          {{ statusProgram.rekomendasi_teknis | formatDate }}
        </p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.persetujuan_dirjen"
        :variant="(statusProgram.persetujuan_dirjen) ? 'info': 'secondary'"
        class="pb-50"
      >
        <h6>Persetujuan Eselon 1</h6>
        <p>{{ statusProgram.persetujuan_dirjen | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.penerbitan_surat"
        :variant="(statusProgram.penerbitan_surat) ? 'info': 'secondary'"
        class="pb-50"
      >
        <h6>Penerbitan Surat</h6>
        <p>{{ statusProgram.penerbitan_surat | formatDate }}</p>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard, BBadge } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BBadge,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      show: false,
      data_utama_id: '',
      statusProgram: {},
    }
  },
  mounted() {
    this.data_utama_id = this.$route.params.id
    this.getStatus()
  },
  methods: {
    getStatus() {
      this.$http.get('/clearance/core-data/get-status-kegiatan', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.statusProgram = res.data.data
          }
        })
    },
    formatStatus(val) {
      const status = {
        MENPAN: 'KEMENPANRB',
        KOMINFO: 'KEMENKOMINFO',
        LAIP: 'KEMENKOMINFO LAIP',
        TAKEL: 'KEMENKOMINFO TAKEL',
        BAPPENAS: 'BAPPENAS',
        BAPENAS: 'BAPPENAS',
      }

      return status[val]
    },
  },
}
</script>

<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Simpan"
      back-button-text="Sebelumnya"
      class="vertical-steps steps-transparent mb-3"
      @on-complete="submitForm"
      @on-change="stepCheck"
    >

      <!-- Data Utama tab -->
      <!-- :before-change="validateDataUtama" -->
      <tab-content
        title="Data Utama"
        :before-change="validateDataUtama"
        :lazy="true"
      >
        <validation-observer ref="formDataUtama">
          <b-form v-if="stepAnggaran === 'krisna'">
            <ubah-data-utama-krisna
              :core-data="coreData"
              :action-step="stepDataUtama"
              @step-completed="stepCompleted"
            />
          </b-form>
          <b-form v-if="stepAnggaran === 'sakti'">
            <ubah-data-utama-baru
              :core-data="coreData"
              :action-step="stepDataUtama"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Peta Rencana tab -->
      <!-- :before-change="validatePetaRencana" -->
      <tab-content
        title="Peta Rencana"
        :before-change="validatePetaRencana"
        :lazy="true"
      >
        <validation-observer ref="formPetaRencana">
          <b-form>
            <ubah-peta-rencana
              :core-data="coreData"
              :action-step="stepPetaRencana"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Infrastruktur -->
      <!-- :before-change="validateInfra" -->
      <tab-content
        title="Infrastruktur"
        :before-change="validateInfra"
        :lazy="true"
      >
        <validation-observer ref="formInfra">
          <b-form>
            <ubah-infrastruktur-single
              :core-data="coreData"
              :sia-guid="siaGuid"
              :sia-kegiatan-guid="siaKegGuid"
              :sia-map-data="siaMap"
              :action-step="stepInfra"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- Proses Bisnis tab -->
      <!-- :before-change="validateBisnis" -->
      <tab-content
        title="Proses Bisnis"
        :before-change="validateBisnis"
        :lazy="true"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Proses Bisnis
            </h5>
            <small class="text-muted">Arsitektur Domain Proses Bisnis SPBE yang terkait dengan kegiatan.</small>
          </b-col>
        </b-row>
        <div
          v-for="(item, index) in totalProbis"
          :id="`probis-${item.proses_bisnis_id}`"
          :key="item.proses_bisnis_id"
          ref="row"
          :title="`Data Proses Bisnis : ${item.nama_urusan}`"
        >
          <div class="border-bottom mb-0 card-text p-0 d-flex">
            <b-button
              v-b-toggle
              :href="`#collapse-probis-${item.proses_bisnis_id}`"
              variant="flat-secondary"
              class="text-left flex-grow-1"
              @click.prevent
            >
              {{ item.nama_urusan }}
              <b-badge
                v-if="item.is_verified_asdep || item.is_verified_bapenas"
                :variant="(item.is_verified_asdep === 1 || item.is_verified_bapenas === 1) ? 'light-success' : 'light-warning'"
              >
                {{ (item.is_verified_asdep === 1 || item.is_verified_bapenas === 1) ? 'Terverifikasi' : 'Perlu Perbaikan' }}
              </b-badge>
              <b-badge
                :id="`error-probis-${item.proses_bisnis_id}`"
                variant="light-danger"
                class="hidden"
              >
                Error Found
              </b-badge>
            </b-button>

            <b-button
              variant="flat-danger"
              @click="removeProbis(index, item)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
          <b-collapse
            :id="`collapse-probis-${item.proses_bisnis_id}`"
            class="mb-1"
            visible
          >
            <ubah-bisnis-multi
              :app-data="item"
              :core-data="coreData"
              :reference-data="probisRefData"
              :sakti="sakti"
              :action-step="stepBisnis"
              @step-completed="stepCompleted"
            />
          </b-collapse>
        </div>

        <div
          v-for="(item, index) in totalProbisBaru"
          :id="`probis-new-${item.id}`"
          :key="item.id"
          ref="row"
          :title="`Data Proses Bisnis ${item.id}`"
        >
          <div class="border-bottom mb-0 card-text p-0 d-flex">
            <b-button
              v-b-toggle
              :href="`#collapse-probis-new-${item.id}`"
              variant="flat-secondary"
              class="text-left flex-grow-1"
              @click.prevent
            >
              Data Proses Bisnis Baru {{ item.id }}
              <b-badge
                :id="`error-probis-new-${item.id}`"
                variant="light-danger"
                class="hidden"
              >
                Error Found
              </b-badge>
            </b-button>
            <b-button
              variant="flat-danger"
              @click="removeProbisBaru(index)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
          <b-collapse
            :id="`collapse-probis-new-${item.id}`"
            class="mb-1"
            visible
          >
            <tambah-bisnis-multi
              :app-data="item"
              :core-data="coreData"
              :clearance-id="data_utama_id"
              :reference-data="probisRefData"
              :sakti="sakti"
              :action-step="stepBisnis"
              @step-completed="stepCompleted"
            />
          </b-collapse>
        </div>

        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-button
              variant="success"
              class="mt-0 mt-md-2"
              @click="repeateProbis"
            >
              <span>Tambah Referensi Proses Bisnis</span>
            </b-button>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Aplikasi -->
      <!-- :before-change="validateAplikasi" -->
      <tab-content
        title="Aplikasi"
        :before-change="validateAplikasi"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Aplikasi
            </h5>
            <small class="text-muted">Arsitektur Domain Aplikasi SPBE untuk seluruh Aplikasi yang terkait dengan kegiatan.</small>
          </b-col>
        </b-row>

        <div
          v-for="(item, index) in totalAplikasi"
          :id="`aplikasi-${item.id}`"
          :key="item.id"
          ref="row"
          :title="`Data Aplikasi : ${item.nama_aplikasi}`"
        >
          <div class="border-bottom mb-0 card-text p-0 d-flex">
            <b-button
              v-b-toggle
              :href="`#collapse-app-${item.id}`"
              variant="flat-secondary"
              class="text-left flex-grow-1"
              @click.prevent
            >
              {{ item.nama_aplikasi }}
              <b-badge
                v-if="item.is_verified === 1 || item.is_verified === -1"
                :variant="(item.is_verified === 1) ? 'light-success' : 'light-warning'"
              >
                {{ (item.is_verified === 1) ? 'Terverifikasi' : 'Perlu Perbaikan' }}
              </b-badge>
              <b-badge
                :id="`error-app-${item.id}`"
                variant="light-danger"
                class="hidden"
              >
                Error Found
              </b-badge>
            </b-button>
            <b-button
              variant="flat-danger"
              @click="removeAplikasi(index, item)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
          <b-collapse
            :id="`collapse-app-${item.id}`"
            class="mb-1"
          >
            <ubah-aplikasi-multi
              :core-data="coreData"
              :reference-data="appRefData"
              :app-data="item"
              :action-step="stepAplikasi"
              @step-completed="stepCompleted"
            />
          </b-collapse>
        </div>

        <div
          v-for="(item, index) in totalAplikasiBaru"
          :id="`aplikasi-${item.id}`"
          :key="item.id"
          ref="row"
          :title="`Data Aplikasi ${item.id}`"
        >
          <div class="border-bottom mb-0 card-text p-0 d-flex">
            <b-button
              v-b-toggle
              :href="`#collapse-app-new-${item.id}`"
              variant="flat-secondary"
              class="text-left flex-grow-1"
              @click.prevent
            >
              Data Aplikasi Baru {{ item.id }}
              <b-badge
                :id="`error-app-new-${item.id}`"
                variant="light-danger"
                class="hidden"
              >
                Error Found
              </b-badge>
            </b-button>
            <b-button
              variant="flat-danger"
              @click="removeAplikasiBaru(index)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
          <b-collapse
            :id="`collapse-app-new-${item.id}`"
            class="mb-1"
            visible
          >
            <tambah-aplikasi-multi
              :core-data="coreData"
              :new-index="index"
              :app-data="item"
              :clearance-id="data_utama_id"
              :action-step="stepAplikasi"
              :reference-data="appRefData"
              @step-completed="stepCompleted"
            />
          </b-collapse>
        </div>

        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-button
              variant="success"
              class="mt-0 mt-md-2"
              @click="repeateAplikasi"
            >
              <span>Tambah Aplikasi Baru</span>
            </b-button>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Layanan -->
      <!-- :before-change="validateLayanan" -->
      <tab-content
        title="Layanan"
        :before-change="validateLayanan"
        :lazy="true"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Layanan
            </h5>
            <small class="text-muted">Arsitektur Domain Layanan SPBE yang terkait dengan kegiatan.</small>
          </b-col>
        </b-row>
        <div
          v-for="(item, index) in totalLayanan"
          :id="`infra-${item.layanan_id}`"
          :key="item.layanan_id"
          ref="row"
          :title="`Data Layanan : ${item.nama_layanan}`"
        >
          <div class="border-bottom mb-0 card-text p-0 d-flex">
            <b-button
              v-b-toggle
              :href="`#collapse-layanan-${item.layanan_id}`"
              variant="flat-secondary"
              class="text-left flex-grow-1"
              @click.prevent
            >
              {{ item.nama_layanan }}
              <b-badge
                v-if="item.is_verified_asdep || item.is_verified_bapenas"
                :variant="(item.is_verified_asdep === 1 || item.is_verified_bapenas === 1) ? 'light-success' : 'light-warning'"
              >
                {{ (item.is_verified_asdep === 1 || item.is_verified_bapenas === 1) ? 'Terverifikasi' : 'Perlu Perbaikan' }}
              </b-badge>
              <b-badge
                :id="`error-layanan-${item.layanan_id}`"
                variant="light-danger"
                class="hidden"
              >
                Error Found
              </b-badge>
            </b-button>
            <b-button
              variant="flat-danger"
              @click="removeLayanan(index, item)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
          <b-collapse
            :id="`collapse-layanan-${item.layanan_id}`"
            class="mb-1"
            visible
          >
            <ubah-layanan-multi
              :app-data="item"
              :core-data="coreData"
              :reference-data="layananRefData"
              :action-step="stepLayanan"
              @step-completed="stepCompleted"
            />
          </b-collapse>
        </div>

        <div
          v-for="(item, index) in totalLayananBaru"
          :id="`layanan-new-${item.id}`"
          :key="item.id"
          ref="row"
          :title="`Data Layanan ${item.id}`"
        >
          <div class="border-bottom mb-0 card-text p-0 d-flex">
            <b-button
              v-b-toggle
              :href="`#collapse-layanan-new-${item.id}`"
              variant="flat-secondary"
              class="text-left flex-grow-1"
              @click.prevent
            >
              Data Layanan Baru {{ item.id }}
              <b-badge
                :id="`error-layanan-new-${item.id}`"
                variant="light-danger"
                class="hidden"
              >
                Error Found
              </b-badge>
            </b-button>
            <b-button
              variant="flat-danger"
              @click="removeLayananBaru(index)"
            >
              <feather-icon
                icon="XIcon"
              />
            </b-button>
          </div>
          <b-collapse
            :id="`collapse-layanan-new-${item.id}`"
            class="mb-1"
            visible
          >
            <tambah-layanan-multi
              :new-index="index"
              :core-data="coreData"
              :app-data="item"
              :clearance-id="data_utama_id"
              :action-step="stepLayanan"
              @step-completed="stepCompleted"
            />
          </b-collapse>
        </div>

        <b-row>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-button
              variant="success"
              class="mt-0 mt-md-2"
              @click="repeateLayanan"
            >
              <span>Tambah Referensi Layanan</span>
            </b-button>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Keamanan -->
      <!-- :before-change="validateKeamanan" -->
      <tab-content
        title="Keamanan"
        :before-change="validateKeamanan"
        :lazy="true"
      >
        <validation-observer ref="formKeamanan">
          <b-form>
            <ubah-keamanan
              :core-data="coreData"
              :action-step="stepKeamanan"
              @step-completed="stepCompleted"
            />
          </b-form>
        </validation-observer>
      </tab-content>

      <div v-if="errorMsg">
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Error Found
          </h4>
          <div class="alert-body">
            <ul v-if="typeof errorMsg === 'object'">
              <li
                v-for="(item, index) in errorMsg"
                :key="index"
              >
                {{ index }}:
                <span
                  v-for="(child, indexs) in item"
                  :key="indexs"
                >
                  {{ child }}
                </span>
              </li>
            </ul>
            <span v-else>{{ errorMsg }}</span>
          </div>
        </b-alert>
      </div>
    </form-wizard>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        {{ alertText }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import { ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BForm, BAlert, VBTooltip, VBToggle, VBModal, BModal, BRow, BCol, BButton, BBadge,
  BCollapse, BCardText,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import UbahDataUtamaBaru from '../ubah-kegiatan/UbahDataUtamaBaru.vue'
import UbahDataUtamaKrisna from '../ubah-kegiatan/UbahDataUtamaKrisna.vue'
import UbahInfrastrukturSingle from '../ubah-kegiatan/UbahInfrastrukturSingle.vue'
import UbahBisnisMulti from '../ubah-kegiatan/UbahBisnisMulti.vue'
import UbahLayananMulti from '../ubah-kegiatan/UbahLayananMulti.vue'
import UbahAplikasiMulti from '../ubah-kegiatan/UbahAplikasiMulti.vue'
import UbahKeamanan from '../ubah-kegiatan/UbahKeamanan.vue'
import UbahPetaRencana from '../ubah-kegiatan/UbahPetaRencana.vue'
import TambahBisnisMulti from '../tambah-kegiatan/TambahBisnisMulti.vue'
import TambahLayananMulti from '../tambah-kegiatan/TambahLayananMulti.vue'
import TambahAplikasiMulti from '../tambah-kegiatan/TambahAplikasiMulti.vue'

export default {
  components: {
    ValidationObserver,
    FormWizard,
    TabContent,
    BCardText,
    BBadge,
    BAlert,
    BModal,
    BForm,
    BRow,
    BCol,
    BButton,
    BCollapse,

    UbahDataUtamaBaru,
    UbahDataUtamaKrisna,
    UbahInfrastrukturSingle,
    UbahBisnisMulti,
    UbahLayananMulti,
    UbahAplikasiMulti,
    UbahKeamanan,
    UbahPetaRencana,
    TambahBisnisMulti,
    TambahLayananMulti,
    TambahAplikasiMulti,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      stepAnggaran: 'krisna',
      userData: getUserData(),
      stepChange: 0,
      stepDataUtama: 0,
      stepPetaRencana: 0,
      stepInfra: 0,
      stepBisnis: 0,
      stepLayanan: 0,
      stepKeamanan: 0,
      stepAplikasi: 0,
      letter_id: '',
      jenis_pengadaan_id: 0,
      data_utama_id: 0,
      alertText: '',
      errorMsg: '',
      form: {},
      message: '',
      totalLayanan: [],
      jumlahLayanan: 0,
      totalLayananBaru: [],
      jumlahLayananBaru: 0,
      totalProbis: [],
      jumlahProbis: 0,
      totalProbisBaru: [],
      jumlahProbisBaru: 0,
      infRefData: {},
      probisRefData: {},
      layananRefData: {},
      totalAplikasi: [],
      jumlahAplikasi: 0,
      totalAplikasiBaru: [],
      jumlahAplikasiBaru: 0,
      appRefData: {},
      sakti: {},
      toDelPath: '',
      toDelData: '',
      toDelItem: '',
      siaGuid: '',
      siaKegGuid: '',
      siaMap: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 99 || role > 110) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/pengajuan/surat')
    } else {
      next()
    }
  },
  mounted() {
    if (process.env.VUE_APP_STEP === 'sakti') {
      this.stepAnggaran = 'sakti'
    }
    this.data_utama_id = Number(this.$route.params.id)
    this.getPreview()
  },
  methods: {
    getPreview() {
      this.$http.get('/clearance/preview', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.totalProbis = res.data.data.program
            this.jumlahProbis = this.totalProbis.length

            this.totalLayanan = res.data.data.layanan
            this.jumlahLayanan = this.totalLayanan.length

            this.totalAplikasi = res.data.data.aplikasi
            this.jumlahAplikasi = this.totalAplikasi.length

            this.getProbisReference()
            this.getLayananReference()
            this.getAppReference()
          }
        })
    },
    getProbisReference() {
      this.$http.get('/clearance/business-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.probisRefData = res.data.data
          }
        })
    },
    getAppReference() {
      this.$http.get('/clearance/applications/app-list', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.appRefData = res.data.data
          }
        })
    },
    getLayananReference() {
      this.$http.get('/clearance/services-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.layananRefData = res.data.data
          }
        })
    },
    repeateAplikasi() {
      const jumlah = this.jumlahAplikasiBaru + 1
      this.totalAplikasiBaru.push({
        id: jumlah,
        ubah: true,
      })
      this.jumlahAplikasiBaru = jumlah
    },
    removeAplikasiBaru(data) {
      this.totalAplikasiBaru.splice(data, 1)
      this.jumlahAplikasiBaru -= 1
    },
    removeAplikasi(data, target) {
      this.alertText = `Apakah anda yakin untuk menghapus Aplikasi ${target.nama_aplikasi}?`
      this.toDelPath = 'applications'
      this.toDelData = target.id
      this.toDelItem = data
      this.$refs['modal-warning'].show()
    },
    repeateLayanan() {
      const jumlah = this.jumlahLayananBaru + 1
      this.totalLayananBaru.push({
        id: jumlah,
      })
      this.jumlahLayananBaru = jumlah
    },
    removeLayananBaru(data) {
      this.totalLayananBaru.splice(data, 1)
      this.jumlahLayananBaru -= 1
    },
    removeLayanan(data, target) {
      this.alertText = `Apakah anda yakin untuk menghapus Referensi Layanan ${target.nama_layanan}?`
      this.toDelPath = 'services-data'
      this.toDelData = target.layanan_id
      this.toDelItem = data
      this.$refs['modal-warning'].show()
    },
    repeateProbis() {
      const jumlah = this.jumlahProbisBaru + 1
      this.totalProbisBaru.push({
        id: jumlah,
      })
      this.jumlahProbisBaru = jumlah
    },
    removeProbisBaru(data) {
      this.totalProbisBaru.splice(data, 1)
      this.jumlahProbisBaru -= 1
    },
    removeProbis(data, target) {
      this.alertText = `Apakah anda yakin untuk menghapus Referensi Proses Bisnis ${target.nama_urusan}?`
      this.toDelPath = 'business-data'
      this.toDelData = target.proses_bisnis_id
      this.toDelItem = data
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelPath = ''
      this.toDelData = ''
      this.toDelItem = ''
    },
    deleteItem() {
      const params = {
        data_utama_id: this.data_utama_id,
      }
      if (this.toDelPath === 'business-data') {
        params.proses_bisnis_id = this.toDelData
      } else if (this.toDelPath === 'services-data') {
        params.layanan_id = this.toDelData
      } else if (this.toDelPath === 'applications') {
        params.aplikasi_id = this.toDelData
      }
      this.$http.post(`/clearance/${this.toDelPath}/delete?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            if (this.toDelPath === 'business-data') {
              this.totalProbis.splice(this.toDelItem, 1)
              this.jumlahProbis -= 1
            } else if (this.toDelPath === 'services-data') {
              this.totalLayanan.splice(this.toDelItem, 1)
              this.jumlahLayanan -= 1
            } else if (this.toDelPath === 'applications') {
              this.totalAplikasi.splice(this.toDelItem, 1)
              this.jumlahAplikasi -= 1
            }
            this.clearDeleteItem()
          }
        })
        .catch(error => {
          this.errorMsg = error.response.data.error
        })
    },
    validateDataUtama() {
      this.$refs.formDataUtama.validate()
        .then(this.stepDataUtama += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    async validatePetaRencana() {
      const isValid = await this.$refs.formPetaRencana.validate()
      if (isValid) {
        this.stepPetaRencana += 1
        this.errorMsg = ''

        return new Promise(resolve => {
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
            } else {
            // this.data_utama_id = value.msg.data_utama_id
              this.errorMsg = ''
            }
            resolve(value.status)
          })
        })
      }
      this.errorMsg = 'Validation failed'
      return false

      // this.$refs.formPetaRencana.validate()
      //   .then(this.stepPetaRencana += 1)

      // return new Promise(resolve => {
      //   this.$on('step-validated', value => {
      //     if (!value.status) {
      //       this.errorMsg = value.msg
      //     } else {
      //       // this.data_utama_id = value.msg.data_utama_id
      //       this.errorMsg = ''
      //     }
      //     resolve(value.status)
      //   })
      // })
    },
    validateInfra() {
      this.$refs.formInfra.validate()
        .then(this.stepInfra += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    validateBisnis() {
      const appValidated = []
      if (this.jumlahProbis !== 0 || this.jumlahProbisBaru !== 0) {
        return new Promise(resolve => {
          this.stepBisnis += 1
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
              appValidated.push(value.id)
              if (value.type === 'probis') {
                document.getElementById(`error-probis-${value.index}`).classList.remove('hidden')
              }
            } else {
              if (value.id && value.type === 'probis') {
                const index = (value.index) ? value.index : value.id
                document.getElementById(`error-probis-${index}`).classList.add('hidden')
              }
              this.errorMsg = ''
            }
            if (appValidated.length !== 0) {
              resolve(false)
            } else {
              this.errorMsg = ''
              resolve(true)
            }
          })
        })
      }
      this.errorMsg = 'Referensi Proses Bisnis Tidak Boleh Kosong'
      return false
    },
    validateLayanan() {
      const appValidated = []
      if (this.jumlahLayanan !== 0 || this.jumlahLayananBaru !== 0) {
        return new Promise(resolve => {
          this.stepLayanan += 1
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
              appValidated.push(value.id)
              if (value.type === 'layanan') {
                document.getElementById(`error-layanan-${value.index}`).classList.remove('hidden')
              }
            } else {
              if (value.id && value.type === 'layanan') {
                const index = (value.index) ? value.index : value.id
                document.getElementById(`error-layanan-${index}`).classList.add('hidden')
              }
              this.errorMsg = ''
            }
            if (appValidated.length !== 0) {
              resolve(false)
            } else {
              this.errorMsg = ''
              resolve(true)
            }
          })
        })
      }
      this.errorMsg = 'Referensi Layanan Tidak Boleh Kosong'
      return false
    },
    validateAplikasi() {
      const appValidated = []
      if (this.jumlahAplikasi !== 0 || this.jumlahAplikasiBaru !== 0) {
        return new Promise(resolve => {
          this.stepAplikasi += 1
          this.$on('step-validated', value => {
            if (!value.status) {
              this.errorMsg = value.msg
              appValidated.push(value.id)
              if (value.type === 'app') {
                document.getElementById(`error-app-${value.index}`).classList.remove('hidden')
              }
            } else {
              if (value.id && value.type === 'app') {
                const index = (value.index) ? value.index : value.id
                document.getElementById(`error-app-${index}`).classList.add('hidden')
              }
              this.errorMsg = ''
            }
            if (appValidated.length !== 0) {
              resolve(false)
            } else {
              this.errorMsg = ''
              resolve(true)
            }
          })
        })
      }
      this.errorMsg = 'Referensi Aplikasi Tidak Boleh Kosong'
      return false
    },
    validateKeamanan() {
      this.$refs.formKeamanan.validate()
        .then(this.stepKeamanan += 1)

      return new Promise(resolve => {
        this.$on('step-validated', value => {
          if (!value.status) {
            this.errorMsg = value.msg
          } else {
            this.errorMsg = ''
          }
          resolve(value.status)
        })
      })
    },
    submitForm() {
      this.$router.replace({ name: 'kegiatan-detail', params: { id: this.data_utama_id } })
    },
    stepCheck(e) {
      this.stepChange = e
      this.errorMsg = ''
    },
    stepCompleted(data) {
      this.sakti = store.state.app.sakti
      this.siaGuid = store.state.app.siaGuid
      this.siaKegGuid = store.state.app.siaKegiatanGuid
      this.siaMap = store.state.app.siaMap
      this.$emit('step-validated', data)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
